<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="itemData" no-body>
      <b-badge
        variant="danger"
        class="p-1"
        style="font-size: 15px"
        v-if="!delCat"
        >Archived Data</b-badge
      >
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="itemData.cover"
                :alt="`Image of ${itemTrans_name}`"
                class="product-img img-fluid"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ itemTrans_name }}</h4>
            <br />
            <b-card-text class="item-company mb-0">
              <h5>
                <span>Brand:</span>
                <b-link :to="{ path: '/Show-Brand/' + brand_id }">
                  {{ brandTrans_name }}
                </b-link>
              </h5>
            </b-card-text>
            <br />
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <h5>
                <span>Category:</span>
                <b-link :to="{ path: '/Show-Category/' + cat_id }">
                  {{ catTrans_name }}</b-link
                >
              </h5>
            </b-card-text>
            <br />
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h5>Rate:</h5>
              <ul class="unstyled-list list-inline pl-1">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= average_rate },
                      star <= average_rate ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <br />
            <!-- Avability -->
            <b-card-text v-if="itemData.active"
              >Active -
              <b-badge variant="success"> {{ itemData.active }} </b-badge>
            </b-card-text>
            <b-card-text v-else
              >Active -
              <b-badge variant="danger"> {{ itemData.active }} </b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'enabled'"
              >Status -
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'disabled'"
              >Status -
              <b-badge variant="danger">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'review'"
              >Status -
              <b-badge variant="warning">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'rejected'"
              >Status -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <!-- IS Guide -->
            <b-card-text>
              Is Guide -
              <b-badge :variant="is_guide ? 'success' : 'danger'">
                {{ itemData.is_guide ? 'True' : 'False' }}
              </b-badge>
            </b-card-text>
            <!-- Product Short Description -->
            <h5>Short Description:</h5>
            <b-card-text
              class="ml-3"
              v-for="(item, index) in itemTrans"
              :key="index"
            >
              <p v-html="item.short_description"></p>
            </b-card-text>
            <hr />
            <div v-if="delCat" class="">
              <b-button

                block
                v-if="authPermissions.includes('update-phone-guide') && item_slug && itemData.is_guide"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                :href=" app_env != 'statging' ?  WebsiteLink + item_slug : StagingWebsiteLink + item_slug" 
                target="_blank"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>Show In Website</span>
              </b-button>
              <b-button
                block
                v-if="authPermissions.includes('update-phone-guide')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :to="{ path: '/UpdateItemCovers/' + itemData.id }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Edit Covers Color</span>
              </b-button>
              <b-button
                block
                v-if="authPermissions.includes('update-phone-guide')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                :to="{ path: '/UpdateMainData/' + itemData.id }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Update Main Data</span>
              </b-button>
              <b-button
                block
                v-if="authPermissions.includes('create-phone-guide')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :to="{ path: '/duplicate/' + itemData.id }"
              >
                <feather-icon icon="PlusSquareIcon" class="mr-50" />
                <span>Duplicate</span>
              </b-button>
              <b-button
                block
                v-show="itemData.active == false"
                v-if="authPermissions.includes('update-phone-guide')"
                @click="activeItem(itemData.id)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
              >
                <feather-icon icon="" class="mr-50" /> Active
              </b-button>
              <b-button
                block
                v-show="itemData.active == true"
                v-b-modal.modal-deactive
                v-if="authPermissions.includes('update-phone-guide')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
              >
                <feather-icon icon="" class="mr-50" /> Deactive
              </b-button>
              <b-button
                block
                v-b-modal.modal-delete
                v-if="authPermissions.includes('delete-phone-guide')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span>Delete Item</span>
              </b-button>
              <b-modal
                id="modal-delete"
                cancel-variant="outline-secondary"
                @ok="deleteItem(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Delete Product"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="deletedItem"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Delete
                  </b-form-checkbox>
                </b-form>
              </b-modal>
              <b-modal
                id="modal-deactive"
                cancel-variant="outline-secondary"
                @ok="deActiveItem(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Deactive Account"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="deactive"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Deactive
                  </b-form-checkbox>
                </b-form>
              </b-modal>
            </div>
            <div v-else class="d-flex justify-content-center">
              <b-button
                block
                v-if="authPermissions.includes('restore-phone-guide')"
                @click="restoreItem(itemData.id)"
                variant="outline-danger"
              >
                Restore
              </b-button>
            </div>
            <hr />
            <div
              v-if="delCat"
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ itemData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Updated at</h6>
                <h3 class="mb-0">
                  {{ itemData.updated_at }}
                </h3>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ itemData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Deleted at</h6>
                <h3 class="mb-0">
                  {{ itemData.deleted_at }}
                </h3>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="Main Specification" v-if="itemData.attributes != null">
      <div>
        <table class="table text-left">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">Specification</th>
              <th scope="col">Values</th>
              <th v-if="authPermissions.includes('update')" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in itemData.attributes"
              class="mb-2 mt-2"
              :key="index"
              :rowspan="item.itemValues.length"
            >
              <th scope="row">{{ item.id }}</th>
              <th scope="row">{{ item.translation.name }}</th>

              <td class="">
                <div
                  v-for="(val, ind) in item.itemValues"
                  class="border-0"
                  :key="ind"
                >
                  <td :class="item.itemValues.length > 1 ? '' : 'border-0'">
                    {{ val.translation.name }}
                  </td>
                </div>
              </td>

              <td>
                <div class="d-flex justify-content-start align-items-center">
                  <div
                    v-if="authPermissions.includes('update-phone-guide')"
                    style="margin: 5px"
                  >
                    <!-- assign button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      :to="{
                        path:
                          '/ShowPhoneGuide/' +
                          itemData.id +
                          '/AssignValue/' +
                          item.id,
                      }"
                    >
                      Assign
                    </b-button>
                  </div>
                  <div
                    v-if="authPermissions.includes('update-phone-guide')"
                    style="margin: 5px"
                  >
                    <!-- revoke button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      type="submit"
                      :to="{
                        path:
                          '/ShowPhoneGuide/' +
                          itemData.id +
                          '/RevokeValue/' +
                          item.id,
                      }"
                    >
                      Revoke
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!-- tags section -->
    <b-card title="Tags">
      <div
        v-for="(tag, index) in itemData.tags"
        :key="index"
        class="d-inline p-2"
      >
        <span style="font-size: 18px"
          ><b-badge variant="primary" class="p-1 badge-glow badge-lg">{{
            tag.name
          }}</b-badge></span
        >
      </div>
      <div>
        <b-button
          v-if="authPermissions.includes('update-phone-guide')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="warning"
          block
          class="btn-cart mt-3"
          :to="{ path: '/UpdateTags/' + itemData.id }"
        >
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span>Update</span>
        </b-button>
      </div>
    </b-card>
    <!-- prices section -->
    <b-card title="Prices">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mb-1 mb-sm-0">
          <span class="text-muted">Commercial networks and enterprises</span>
        </div>
        <div class="d-flex align-content-center mb-1 mb-sm-0">
          <h1 class="font-weight-bolder">
            {{ lastPrice }}
          </h1>
          <div class="pt-25 ml-75">
            <b-badge variant="light-secondary">
              <feather-icon
                v-if="priceArrowDown"
                icon="ArrowDownIcon"
                size="12"
                class="text-danger align-middle"
              />
              <feather-icon
                v-if="priceArrowUp"
                icon="ArrowUpIcon"
                size="12"
                class="text-success align-middle"
              />
              <span class="font-weight-bolder align-middle">
                {{ pricePerc }}%</span
              >
            </b-badge>
          </div>
        </div>
      </div>
      <!-- prices echart -->
      <app-echart-line :option-data="option" />
      <!-- prices table -->
      <b-table
        :sticky-header="stickyHeader"
        :no-border-collapse="noCollapse"
        responsive
        :items="itemData.item_prices"
        :fields="fields"
      >
        <template #cell(actions)="items">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="authPermissions.includes('update-phone-guide')"
              block
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mr-1 mt-1"
              type="submit"
              :to="{ path: '/UpdatePrice/' + items.item.id }"
            >
              Update
            </b-button>

            <b-button
              v-if="
                authPermissions.includes('delete') &&
                itemData.item_prices[0].id != items.item.id
              "
              block
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1 mt-1"
              variant="danger"
              type="submit"
              @click="deletePrice(items.item.id)"
            >
              Delete
            </b-button>
          </div>
        </template>
        <template #cell(description)="data">
          <p v-html="data.value"></p>
        </template>
      </b-table>
      <!-- add new price -->
      <b-button
        v-if="authPermissions.includes('update-phone-guide')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="success"
        block
        class="btn-cart mt-3"
        :to="{ path: '/addNewPrice/' + itemData.id }"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add Price</span>
      </b-button>
    </b-card>
    <!-- translations section -->
    <b-card title="Descriptions">
      <b-tabs align="center" class="text-center">
        <b-tab
          v-for="(translation, index) in itemTrans"
          :key="index"
          :title="translation.locale == 'en' ? 'English' : 'Arabic'"
        >
          <b-card-text><p  v-html="translation.description"></p> </b-card-text>
          <b-button
            v-if="authPermissions.includes('update-phone-guide')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="warning"
            class="btn-cart"
            :to="{ path: '/UpdateTranslation/' + translation.id }"
          >
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span>Update {{ translation.locale }} translation text</span>
          </b-button>
          <b-button
            v-if="
              authPermissions.includes('delete') &&
              translation.locale != 'en' &&
              translation.locale != 'ar'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="danger"
            class="btn-cart ml-1"
            @click="deleteTranslation(translation.id)"
          >
            <feather-icon icon="Trash2Icon" class="mr-50" />
            <span>Delete {{ translation.locale }} translation text</span>
          </b-button>
        </b-tab>
      </b-tabs>
      <!-- add new translation -->
      <b-button
        v-if="authPermissions.includes('update-phone-guide')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="success"
        block
        class="btn-cart mt-3"
        :to="{ path: '/addNewTranslation/' + itemData.id }"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add Tranlsation To Text</span>
      </b-button>
    </b-card>
    <b-row
      v-if="parentSet"
      class="d-flex justify-content-center"
      style="margin-top: 90px"
    >
      <!-- Phone Guide Show -->
      <b-col md="4">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar size="114" variant="light" :src="phonegudieCover" />
            </div>
          </div>
          <h2><span style="color: #0d6efd">Parent Phone Guide</span></h2>
          <h3>
            <b-link
              style="color: black"
              :to="{ path: '/ShowPhoneGuide/' + p_ID }"
              >{{ Pname }}
            </b-link>
          </h3>
          <h6 class="text-muted">
            {{ Pdesc }}
          </h6>
          <b-badge class="profile-badge" variant="light-primary">
            {{ aRate }}
          </b-badge>
          <hr class="mb-2" />
          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- photo gallery section -->
    <b-card title="Photo Gallery">
      <CoolLightBox
        :items="imagesArr"
        :index="imgIndex"
        @close="imgIndex = null"
      >
      </CoolLightBox>
      <swiper
        class="swiper-autoplay"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide v-for="(data, index) in photoGallery" :key="index">
          <b-img
            @click="imgIndex = index"
            width="200px"
            height="200px"
            :src="data.src"
            style="display: block; margin: auto"
          />
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <b-button
              v-if="authPermissions.includes('update-phone-guide')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mt-1"
              :to="{
                name: 'EditImage',
                params: { imgID: data.id, image: data.src, desc: data.alt },
              }"
            >
              Update
            </b-button>
            <b-button
              v-if="authPermissions.includes('update-phone-guide')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon ml-1 mt-1"
              @click="deleteImages(data.id)"
            >
              Delete
            </b-button>
          </div>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
      </swiper>
      <!-- add new image -->
      <b-button
        v-if="authPermissions.includes('update-phone-guide')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="success"
        block
        class="btn-cart mt-3"
        :to="{ path: '/AddNewImage/' + itemData.id }"
      >
        <feather-icon icon="RefreshCwIcon" class="mr-50" />
        <span>Add New Image</span>
      </b-button>
    </b-card>
    <!-- taxonomies section -->
    <b-card>
      <h2>Specification</h2>
      <b-tabs>
        <b-tab title="Arabic">
          <b-table-simple
            dir="rtl"
            style="text-align: right"
            hover
            small
            responsive
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th> Specify </b-th>
                <b-th> Descriptions </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, tex) in taxonomiesAR" :key="tex">
              <b-tr>
                <b-th :rowspan="item.length">
                  {{ item[0].name }}
                </b-th>
                <div v-for="(itme, ind) in item" :key="ind">
                  <b-th class="text-right float-left">
                    {{ itme.pivot.name }}
                  </b-th>
                  <b-td
                    v-html="itme.pivot.description"
                    class="text-right float-left"
                  >
                  </b-td>
                  <div
                    v-if="authPermissions.includes('update-phone-guide')">
                    <div class="text-right">
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                          background: transparent !important;
                          border-width: 0;
                        "
                        class="btn-icon mr-0"
                        :to="{
                          path: '/EditTaxonomies/' + id,
                          query: { item: itme },
                        }"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px; color: 021B3A"
                      /></b-button>
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                          background: transparent !important;
                          border-width: 0;
                        "
                        class="btn-icon mr-0"
                        @click="deleteTax(itme.pivot.slug, itme.pivot.term_id)"
                        ><feather-icon
                          variant="primary"
                          icon="Trash2Icon"
                          style="width: 18px; height: 18px; color: 021B3A"
                      /></b-button>
                    </div>
                  </div>
                </div>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td colspan="12" variant="secondary" class="text-right">
                  Total Rows: <b>{{ taxonomiesAR.length }}</b>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td colspan="12">
                  <b-button
                    v-if="authPermissions.includes('update-phone-guide')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    :to="{ path: '/add/specify/' + id, query: { lang: 'ar' } }"
                    variant="success"
                    block
                    class="btn-cart mt-3"
                  >
                    <feather-icon icon="PluseIcon" class="mr-50" />
                    <span>Add Arabic Specify</span>
                  </b-button>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-tab>
        <b-tab title="English">
          <b-table-simple hover small responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th> Specify </b-th>
                <b-th> Descriptions </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, tex) in taxonomiesEN" :key="tex">
              <b-tr>
                <b-th :rowspan="item.length">
                  {{ item[0].name }}
                </b-th>
                <div v-for="(itme, ind) in item" :key="ind">
                  <b-th class="text-right float-left">
                    {{ itme.pivot.name }}
                  </b-th>
                  <b-td class="text-right float-left">
                    {{ itme.pivot.description }}
                  </b-td>
                  <div>
                    <div class="text-right"
                    v-if="authPermissions.includes('update-phone-guide')">
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                          background: transparent !important;
                          border-width: 0;
                        "
                        class="btn-icon mr-0"
                        :to="{
                          path: '/EditTaxonomies/' + id,
                          query: { item: itme },
                        }"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px; color: 021B3A"
                      /></b-button>
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                          background: transparent !important;
                          border-width: 0;
                        "
                        class="btn-icon mr-0"
                        @click="deleteTax(itme.pivot.slug, itme.pivot.term_id)"
                        ><feather-icon
                          variant="primary"
                          icon="Trash2Icon"
                          style="width: 18px; height: 18px; color: 021B3A"
                      /></b-button>
                    </div>
                  </div>
                </div>
              </b-tr>
              <b-tr> </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td colspan="12" variant="secondary" class="text-right">
                  Total Rows: <b>{{ taxonomiesEN.length }}</b>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td colspan="12">
                  <b-button
                    v-if="authPermissions.includes('update-phone-guide')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="success"
                    :to="{ path: '/add/specify/' + id, query: { lang: 'en' } }"
                    block
                    class="btn-cart mt-3"
                  >
                    <feather-icon icon="PluseIcon" class="mr-50" />
                    <span>Add English Specify</span>
                  </b-button>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-tab>
      </b-tabs>
    </b-card>
    <!-- <b-card>
            <b-tabs>
                <b-tab title="Products">
                    <prdoucts-table :link="'items-guide/' + id + '/products-pagi/'"></prdoucts-table>
                </b-tab>  
            </b-tabs>    
        </b-card> -->
    <!-- comments -->
    <b-card title="Comments">
      <div
        v-for="(comment, ind) in itemData.reviews"
        :key="ind"
        class="d-flex w-100 align-items-start mb-1"
      >
        <b-row class="w-100">
          <b-col cols="1">
            <b-avatar
              :src="comment.user_profile"
              size="34"
              class="mt-25 mr-75"
            />
          </b-col>
          <b-col cols="9">
            <div class="profile-user-info w-100">
              <div class="d-flex align-items-center justify-content-between">
                <h6
                  class="mb-0"
                  :to="{ path: '/User/Show/' + comment.user_id }"
                >
                  {{ comment.username }}
                </h6>
              </div>
              <ul class="unstyled-list list-inline pl-1">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= comment.rate },
                      star <= comment.rate ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
              <small>{{ comment.body }}</small>
            </div>
          </b-col>
          <b-col cols="1">
            <b-button
              v-if="authPermissions.includes('delete')"
              @click="deleteReview(comment.id)"
              size="sm"
              variant="danger"
              >Remove</b-button
            >
          </b-col>
          <b-col cols="12">
            <hr style="width: 100%" />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'MyComponent',
  components: {
    AppEchartLine,
    Swiper,
    SwiperSlide,
    CoolLightBox,
  },
  data() {
    return {
      app_env : process.env.VUE_APP_ENV,
      WebsiteLink: 'https://mobilemasr.com/guide/',
      StagingWebsiteLink: 'https://whale-app-5smya.ondigitalocean.app/guide/',
      item_slug:null,
      is_guide: '',
      deactive: '0',
      p_ID: 0,
      parentSet: true,
      phonegudieCover: '',
      Pname: '',
      average_rate: '',
      Pdesc: '',
      aRate: '',
      itemID: '',
      delCat: true,
      imgIndex: null,
      imagesArr: [],
      deletedItem: '0',
      authPermissions: null,
      brand_id: 0,
      cat_id: 0,
      id: 0,
      itemData: {},
      itemComments: [],
      errors_back: [],
      showDismissibleAlert: false,
      itemTrans: null,
      lastPrice: null,
      pricePerc: null,
      priceArrowUp: false,
      priceArrowDown: false,
      itemTrans_name: null,
      catTrans: null,
      catTrans_name: null,
      brandTrans: null,
      brandTrans_name: null,
      taxonomiesAR: [],
      taxonomiesEN: [],
      option: {
        xAxisData: [],
        series: [],
      },
      photoGallery: [],
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: 'id',
          stickyColumn: true,
          isRowHeader: true,
          variant: 'primary',
        },
        {
          key: 'price',
          label: 'Price',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'actions',
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  created() {
    
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.showDismissibleAlert = false
      axios
        .get('items-guide/' + this.id)
        .then((result) => {
          this.itemData = result.data.data

          if (this.itemData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
          // this.average_rate = this.itemData.average_rate
          this.brand_id = this.itemData.brand.id
          this.cat_id = this.itemData.category.id
          this.itemID = this.itemData.id
          this.is_guide = this.itemData.is_guide
          this.itemTrans = this.itemData.item_translations
          this.catTrans = this.itemData.category.translations
          this.brandTrans = this.itemData.brand.translations
          this.option.xAxisData.length = 0
          this.option.series.length = 0
          if (this.itemData.item_prices.length > 0) {
            for (let price in this.itemData.item_prices) {
              this.option.xAxisData.push(this.itemData.item_prices[price].date)
              this.option.series.push(this.itemData.item_prices[price].price)
            }
          }
          if (this.itemData.parent != null) {
            this.p_ID = this.itemData.parent.id
            this.phonegudieCover = this.itemData.parent.cover
            if (this.itemData.parent.item_translations.length > 0) {
              this.Pname = this.itemData.parent.item_translations[0].name
              this.Pdesc =
                this.itemData.parent.item_translations[0].short_description
            }
            console.log(this.item_slug)
            this.aRate = this.itemData.parent.average_rate
            this.parentSet = true
          } else {
            this.parentSet = false
          }
          this.lastPrice =
            this.itemData.item_prices[
              this.itemData.item_prices.length - 1
            ].price
          if (this.itemData.item_prices.length > 1) {
            this.pricePerc = (
              (this.itemData.item_prices[this.itemData.item_prices.length - 2]
                .price /
                this.itemData.item_prices[this.itemData.item_prices.length - 1]
                  .price /
                this.itemData.item_prices[this.itemData.item_prices.length - 2]
                  .price) *
              100 *
              100
            ).toFixed(2)
            if (Math.sign(this.pricePerc) == 1) {
              this.priceArrowUp = false
              this.priceArrowDown = true
            } else if (Math.sign(this.pricePerc) == -1) {
              this.priceArrowUp = true
              this.priceArrowDown = false
            } else {
              this.priceArrowUp = false
              this.priceArrowDown = false
            }
          }
          // if (this.itemData.item_translations.length > 0) {
              this.item_slug = this.itemData.item_translations[0].slug
          this.itemTrans_name = this.itemData.item_translations[0].name
          // }
          //  if (this.itemData.category.translations.length > 0) {
          this.catTrans_name = this.itemData.category.translation.name
          // }
          //  if (this.itemData.brand.translations.length > 0) {
          this.brandTrans_name = this.itemData.brand.translation.name
          // }
          this.photoGallery.length = 0
          this.imagesArr.length = 0
          if (this.itemData.item_images.length > 0) {
            for (let img in this.itemData.item_images) {
              this.photoGallery.push({
                src: this.itemData.item_images[img].file,
                alt: this.itemData.item_images[img].description,
                id: this.itemData.item_images[img].id,
              })
              this.imagesArr.push(this.itemData.item_images[img].file)
            }
          }
          this.taxonomiesAR.length = 0
          this.taxonomiesEN.length = 0
          for (var tax in this.itemData.taxonomies['en']) {
            this.taxonomiesEN.push(this.itemData.taxonomies['en'][tax])
          }
          for (var tax in this.itemData.taxonomies['ar']) {
            this.taxonomiesAR.push(this.itemData.taxonomies['ar'][tax])
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    activeItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to active item.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('items/' + id + '/active-item')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deActiveItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactive item.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('items/' + id + '/deactive-item/' + this.deactive)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    // delete function to delete item
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete item.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('items/' + id + '/archive-item/' + this.deletedItem)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deletePrice(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete price.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('items-guide/prices/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete translation.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('items-guide/translations/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    deleteTax(slug, termID) {
      axios
        .delete(
          'items-guide/' +
            this.id +
            '/taxonomy/' +
            termID +
            '/' +
            slug +
            '/delete'
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    deleteImages(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete image.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('items-guide/images/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteReview(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete comment.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('items-guide/review/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore Item.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('items-guide/' + id + '/restore')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
.table-head-specify {
  max-width: 10%;
}
</style>
